import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?p=215

const EventOrientacni: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="ORIENTAČNÍ"
        link="/event_orientacni"
        author="Algy"
        date="18.03.2014"
        time="13:23"
        category="Eventy"
      />
      <p><strong>VELKÝ NAVIGAČNÍ EVENT</strong></p>
      <p>Tento event se skládá z několika po sobě jdoucích etap. Je určen postavám všech levelů. Zúčastnit se ho může pouze 12 hráčů, kteří se jako první dostaví na určené místo. GM postupně zadává další místa, kam mají postavy dorazit. Přitom vždy vyřadí z eventu poslední tři hráče. Minimálně jedno z etap je let na wyverně. Buffování od jiných postav je v průběhu eventu zakázáno (povolen je sell buff a item buff). Konečné pořadí určuje hráčů pořadí v poslední etapě.</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventOrientacni

export { Head } from '../components/defaultHtmlHead'